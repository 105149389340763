.publish-sidebar {
  flex-direction: column !important;
  > div {
    border-bottom: 1px solid var(--grey-light);
  }

  label {
    margin-top: 0.5rem;
    padding: 0.25rem;
    padding-left: 0.5rem;
    cursor: pointer;
    display: block;
    border-radius: 0.25rem;
    border: 1px solid transparent;

    span {
      line-height: 2rem;
      position: relative;
    }
    input[type='checkbox'] {
      margin-right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
    &:hover {
      background-color: white;
      border-color: lightgray;
    }
    &[disabled] {
      pointer-events: none;
    }
  }

  #publishHelpButton {
    box-shadow: none;
  }

  .targets {
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    padding-block: 0.5rem;
    margin-bottom: 0.75rem;

    .choose-targets {
      ul {
        max-height: 275px;
        overflow-x: clip;
        overflow-y: auto;
      }
    }
    h5 {
      text-align: center;
    }

    .list-group {
      border: 1px solid lightgray;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;

      li {
        border-top: 1px solid lightgray;
        cursor: pointer;

        input[type='checkbox'] {
          margin-right: 0.75rem;
        }
      }

      li:first-child {
        border: none;
      }
    }
  }
}

.publish {
  .empty-list-item {
    color: var(--grey-medium);
    font-weight: bold;
    font-style: italic;
    cursor: default !important;
  }
}

.publish-help {
  color: var(--grey-dark);
  background: transparent;
  margin-top: 0.5rem;
  max-width: fit-content;
  margin-inline: auto;
  &:hover,
  &:focus {
    background-color: transparent;
  }
}

.publish-page {
  padding: 1rem;
  height: 100%;
  overflow: hidden;
  position: relative;

  .page-content {
    border-radius: 0.5rem;
    border: 1px solid var(--grey-light);
    max-width: 80rem;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .changes-table {
      flex-shrink: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .scrollable {
        overflow-x: clip;
        overflow-y: auto;
        min-width: 45rem;
        scrollbar-gutter: stable;
      }

      .v-center {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .col.grid-fix {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 2fr 1fr 1fr 1fr;
        .sub {
          color: var(--grey-medium);
        }
      }

      .row,
      .col.grid-fix div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 0.25rem;
      }

      .row {
        --bs-gutter-x: 0;
        align-items: center;

        &.header {
          color: var(--grey-dark);
          font-weight: bold;
          padding-block: 0;
          min-height: 4rem;
          scrollbar-gutter: stable;

          .sort-button {
            height: 3.5rem;
            line-height: 3.5rem;
            cursor: pointer;
            .sort-indicator {
              margin-left: 0.25rem;
              display: none;
              &.active {
                display: unset;
              }
            }
            &:hover {
              text-decoration: underline;
              .sort-indicator {
                display: unset;
              }
            }
          }
        }

        &.header *[disabled] {
          pointer-events: none;
        }

        .article-link {
          color: black;
          &:hover {
            text-decoration: underline !important;
          }
        }
      }

      > .row,
      .scrollable > .row {
        padding: 1rem;
        border: 1px solid transparent;
        border-bottom: 1px solid var(--grey-light);
        transition: border 0.2s ease;
        position: relative;

        &:last-child {
          border-bottom: 1px solid transparent;
        }

        &:before {
          position: absolute;
          width: 100%;
          height: 100%;
          content: '';
          opacity: 0;
          background: var(--color-primary-dark);
          top: 0;
          left: 0;
          transition: opacity 0.2s ease;
          pointer-events: none;
        }

        &:hover:not(.header):not(.empty-list-item) {
          border: 1px solid var(--color-primary-dark);
          background-color: rgba(var(--color-primary-dark), 0.2);
          &::before {
            opacity: 0.05;
          }
        }

        .check-col {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem;
          padding-right: 2rem;
          flex: 0 0 auto;
          width: auto;

          input[type='checkbox'] {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .page-with-sidebar.publish {
    display: block;
  }
}
