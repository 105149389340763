html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

:root {
  --grayscale-0: white;
  --grayscale-1: #e8eaf3;
  --grayscale-2: #6c757d;
  --grayscale-3: #494949;
  --grayscale-4: black;
  --color-primary: red;
  --color-accent: #f9c532;
  --color-primary-dark: red;
  --color-primary-light: #f9c532;
  --grey-light: rgb(226, 226, 226);
  --grey-medium: rgb(200, 200, 200);
  --grey-dark: rgb(175, 175, 175);
}

a,
a:hover {
  color: $primary-def;
}
a:link,
a:visited,
a:active,
a:hover {
  text-decoration: none !important;
  cursor: pointer;
}

html.project-raddningsregion_mittnorrland {
  header .header-top-row>.left-container img {
    height: 60px;
  }
}
