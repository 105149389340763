.mini-menu {
  margin: 0;
  margin-top: 0.5rem;
  position: absolute;
  right: -3rem;
  width: 3rem;
  background-color: var(--color-primary);
  transition: right 0.2s ease;
  overflow: hidden;
  height: calc(100vh - 6.7rem);

  a:hover {
    transform: scale(1) !important;
  }

  .category-button {
    height: 1.7rem;
    width: 1.7rem;

    .icon {
      background: currentColor;
      color: white;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      width: 100%;
      height: 100%;
      display: inline-block;
      cursor: pointer;

      &:hover {
        animation: add-opacity 1s -0.5s linear paused forwards;
      }
    }
  }

  .active {
    .category-button {
      .icon {
        background: var(--color-primary-light);
      }

      .triangle-indicator {
        transform: rotate(90deg) translateY(50%);
        left: 0 !important;
        bottom: 1rem !important;
      }
    }
  }
}

header {
  &.fullscreen {
    .mini-menu {
      transition: none;
      border-bottom: 2px solid white;

      .active {
        .category-button {
          .triangle-indicator {
            transform: rotate(0deg) translateX(-50%);
            left: 50% !important;
            bottom: -0.3rem !important;
          }
        }
      }

      ol {
        height: 3rem;

        li {
          a {
            display: inline-block;
          }
        }
      }
    }
  }

  &.admin-mode {
    .mini-menu {
      height: calc(100vh - 8.2rem);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .mini-menu {
    right: 0;
    height: calc(100vh - 6.3rem);
  }

  header.fullscreen .mini-menu {
    top: 0;
    right: 0;
    width: 100%;
    height: 3rem;
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  .mini-menu {
    right: -3rem;
  }

  header.fullscreen {
    .mini-menu {
      height: 0;
      right: -3rem;
    }
  }
}
