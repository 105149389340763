.category-navigation {
  padding-top: 0.5rem;
  overflow-x: hidden;

  .nav-article {
    position: relative;
    min-height: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
    word-break: break-word;
    transition: all 0.15s;
    color: grey;
    border-left: 4px solid transparent;

    span {
      padding-left: 1.5rem;
    }

    &:hover {
      background: white;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0;
      background: var(--color-primary-dark);
      transition: all 0.2s;
      pointer-events: none;
    }

    &.article-active {
      border-left: 4px solid var(--color-primary-dark);
      font-weight: bold;
      color: var(--color-primary-dark);

      &:before {
        opacity: 0.15;
      }
    }
    &:hover {
      background: white;
    }
  }

  // .article-active {
  //   background: var(--color-primary-light);
  // }

  .Collapsible {
    h6 {
      margin-top: 0.5em;
    }

    .Collapsible__contentInner {
      min-height: 2rem;
    }
  }
}
