.accounts-search {
  width: fit-content;

  button.dropdown-item:focus {
    background-color: lightgrey;
    color: black;
  }

  input {
    cursor: pointer;
    width: 20rem;
    position: relative;
    border-color: gray;
    &:focus {
      box-shadow: none;
    }
  }

  svg {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    color: gray;
  }

  .list {
    margin-top: 0;
    max-height: 400px;
    width: 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: all 0.2s;
    border-radius: 0 0 0.4rem 0.4rem;

    button {
      border-radius: 0 !important;
      min-height: 2rem;
    }
  }

  .empty-result {
    color: gray;
    font-style: italic;
    pointer-events: none;
  }
}

.accounts-search-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  
  button {
    margin: 0px;
  }
}
