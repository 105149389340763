@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

.editor-wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  padding-top: 1rem;
  word-break: break-word;

  //Fixes for overflowing content from editor
  .fr-box {
    display: flex;
    height: 100%;
    flex-direction: column;

    .fr-wrapper {
      flex: 1;
      height: 100%;
      overflow-y: auto;

      .fr-element {
        min-height: 100%;
      }
    }
  }
}
embed {
  display: block;
  border: 2px dotted red;
  position: relative;
  &::after {
    content: 'Dokument';
    position: absolute;
    border-right: 2px dotted red;
    border-bottom: 2px dotted red;
  }
}

.article-container {
  display: flex;
  flex-direction: column;
  flex: auto;

  iframe {
    flex: auto;
  }
}
@media print {
  .article-container {
    display: flex;
    flex-direction: column;
    flex: auto;
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    float: inherit;
    overflow: visible;
    iframe {
      flex: auto;
    }
  }
}

.btn-outline-secondary {
  background-color: rgba(white, 0.425);
}

.spinner {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border: 6px solid rgba(#00aeef26, 0.15);
  border-top: 6px solid rgba(#00aeefcc, 0.8);
  border-radius: 100%;
}
#overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.5);
  z-index: 2147483647;
}
input[type='checkbox'] {
  cursor: pointer;
}
.small-checkbox input,
.undefined input {
  transform: translateY(2px);
  margin: 0 5px !important;
}
.medium-checkbox input {
  transform: translateY(2px) scale(1.5);
  margin: 2px 7px !important;
}
.big-checkbox input {
  transform: translateY(1px) scale(2.5);
  margin: 12px 12px !important;
}

.size-info-icon {
  position: absolute;
  right: -118px;
  top: -47px;
  z-index: 3;
  color: slategrey;
  cursor: help;
}

.tooltip-size-info {
  position: relative;
  display: inline-block;
}

.tooltip-size-info .tooltiptext-size {
  visibility: hidden;
  opacity: 0;
  font-size: 13px;
  width: 150px;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  top: -20px;
  left: -2px;
  z-index: 3;
  transition: opacity 0.25s;
}

.tooltip-size-info .tooltiptext-size::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 75%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-size-info:hover .tooltiptext-size {
  visibility: visible;
  opacity: 0.8;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
