.page-with-sidebar {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'sidebar pagebody';
  overflow-y: hidden;

  aside {
    grid-area: sidebar;
    width: 19.6rem;
    border-right: 1px solid lightgrey;
    display: flex;
    overflow-y: auto;
    background: #f8f9fa;
    grid-template-columns: 1fr;
    z-index: 1;

    > * {
      width: 100%;
    }
  }

  main {
    grid-area: pagebody;
    display: grid;
  }
}
