.sub-config-container {
  .sub-config-item {
    border-bottom: 1px solid lightgray;
    display: flex;
    align-items: center;
    padding: 0.25rem 0rem;
  }

  .sub-config-name {
    flex: 1;
  }

  .sub-config-item:last-child {
    border-bottom: none;
  }
}
