button {
  position: relative;

  &.light-button,
  &.dark-button,
  &.dark-button:focus {
    border-radius: 0.4rem;
    padding: 0.375rem 0.75rem;
    cursor: pointer;

    // With the custom hover effect we need to override the default padding of reactstrap buttons
    &.div-hover {
      padding: 0 !important;
    }
    > div {
      padding: 0.375rem 0.75rem;
      border-radius: 0.4rem;
      background-color: transparent;
      transition: background-color 0.2s;
    }
    &:hover {
      & > div {
        background-color: rgba(black, 0.1);
      }
    }
  }

  &.light-button {
    border: 1px solid var(--grayscale-1);
    background-color: white;
    color: var(--grayscale-4);
  }

  &.dark-button,
  &.dark-button:focus {
    border: 1px solid var(--color-primary-dark);
    background-color: var(--color-primary-dark);
    color: var(--grayscale-0);
    &:hover {
      background-color: var(--color-primary-dark);
      color: var(--grayscale-0);
    }
  }

  // Hover tooltip
  .tooltip-content {
    // font-size: small;
    visibility: hidden;
    width: max-content;
    background-color: rgba(black, 0.7);
    text-align: center;
    border-radius: 0.4em;
    padding: 0.2em 0.4em;
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
    color: white;
    z-index: 10;
    cursor: auto;
    pointer-events: none;

    &.top {
      bottom: 115%;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        top: 100%;
        left: 50%;
        border-color: rgba(black, 0.7) transparent transparent transparent;
        transform: translateX(-50%);
      }
    }
    &.bottom {
      top: 115%;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        left: 50%;
        bottom: 100%;
        border-color: transparent transparent rgba(black, 0.7) transparent;
        transform: translateX(-50%);
      }
    }
    &.left {
      right: 115%;
      top: 50%;
      transform: translateY(-50%);
      &::after {
        left: 100%;
        top: 50%;
        border-color: transparent transparent transparent rgba(black, 0.7);
        transform: translateY(-50%);
      }
    }
    &.right {
      left: 115%;
      top: 50%;
      transform: translateY(-50%);
      &::after {
        right: 100%;
        top: 50%;
        border-color: transparent rgba(black, 0.7) transparent transparent;
        transform: translateY(-50%);
      }
    }
  }

  &:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5s;
  }

  .tooltip-content::after {
    content: ' ';
    position: absolute;
    border-width: 5px;
    border-style: solid;
  }

  &.hover-half-active.light-button:hover {
    background-color: rgba(black, 0.5) !important;
    color: white;
    transition: all 0.3s;
    cursor: pointer;
  }

  .mini-clickable-button {
    padding-left: 0.2rem;
  }

  [data-icon='spinner'] {
    animation: spin infinite 2s linear;
  }

  @keyframes spin {
    from {
      transform: translateY(1px) rotate(0deg);
    }
    to {
      transform: translateY(1px) rotate(360deg);
    }
  }
}
