.footer-box {
  background-color: var(--color-primary);
  font-size: 14px;
  text-align: center;
  height: 1.8rem;
  transition: height 0.2s ease, font-size 0.2s ease, padding 0.2s ease;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  display: flex;

  a,
  div {
    display: inline-block;
    text-align: center;
    height: 100%;
  }
}

.footer-box.fullscreen {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .footer-box {
    padding: 0.25rem 3rem 0.25rem 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .footer-box {
    height: 1.5rem;
    font-size: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .footer-box {
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
    font-size: 8px;
    a,
    div {
      line-height: 16px;
    }
  }
}
