.subscription-interval-group {
  text-align: center;
}

.subscription-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;

  .subscription-card {
    min-width: 15rem;
    min-height: 30rem;
    max-width: 25rem;
    width: 23%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(black, 0.1);
    border-radius: 0.4rem;
    margin: 1rem 0;
    position: relative;
    padding: 3rem 1rem 0.5rem 1rem;

    .subscription-card-banner {
      background-color: var(--color-primary-dark);
      color: white;
      text-align: center;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      padding: 0.5rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .subscription-card-content {
      display: flex;
      flex-direction: column;
      padding-bottom: 0.5rem;
      flex: 1;
      h2,
      h4 {
        font-weight: bold;
        text-align: center;
      }
      svg {
        margin-right: 0.5rem;
      }
    }
  }
}
