.button-holder {
  display: flex;
  justify-content: center;
}

.noTags {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.tag-name-div input {
  display: inline;
}

.tag-name-input {
  width: 50%;
}

.tag-col {
  max-width: 15%;
}

.tags-display {
  width: 99%;
  background-color: #ddd;
  .tag {
    display: inline;
  }
}

.article-value-list {
  width: 99%;

  div.row:first-child .sort-button {
    width: 100%;
    max-width: 100%;
  }

  div.row div.col {
    text-overflow: ellipsis;

    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }

  div.row:nth-child(odd) {
    background: #ddd;
  }

  div.col:first-child,
  div.col:last-child {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
    word-break: break-all;
  }

  div.row {
    height: 50px;
    line-height: 50px;
  }

  div.row input {
    vertical-align: middle;
  }
}
