.padded-container {
  padding: 1rem;
  overflow-y: scroll;
}

.centered-content {
  display: flex;
  justify-items: center;
}

button {
  margin: 0.2em;
}

html {
  height: 100%;
  width: 100%;
}

:root {
  --grayscale-0: white;
  --grayscale-1: #e8eaf3;
  --grayscale-2: #6c757d;
  --grayscale-3: #494949;
  --grayscale-4: black;
  --color-primary: red;
  --color-accent: #f9c532;
  --color-primary-dark: red;
  --color-primary-light: #f9c532;
}

.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after {
  border-bottom-color: #f0f0f0;
}
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after {
  border-right-color: #f0f0f0;
}
