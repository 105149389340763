.categories-as-grid {
  display: grid;
  grid-template-columns: repeat(2, 12rem);
  gap: 0.2rem;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 1rem;
  position: relative;
  height: 9rem;
  width: min(10rem, 100%);
  max-width: 10rem;

  .icon {
    max-width: 30%;
    max-height: 30%;
  }

  .name {
    min-height: 2rem;
    text-align: center;
    font-weight: bold;
    padding: 10px;
  }

  .edit {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

.image-upload-container {
  border: 2px dashed #ccc;
  border-radius: 0.4rem;
  padding: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 5rem;
    height: 5rem;
    display: block;
    justify-self: center;
  }
}

.file-upload-button {
  background-color: var(--color-primary-dark);
  display: block;
  width: 100%;
  color: white;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
}

.acc-mng-module {
  border: 1px solid rgba(black, 0.1);
  border-radius: 0.4rem;
  padding: 0.5rem 0.9rem;
  margin: 1rem 0;
  max-width: 28rem;
}

.settings-page {
  .save-update-index-button {
    height: 3rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    background-color: var(--color-primary);
    color: var(--color-primary-light);
    border-radius: 0.2rem;
    cursor: pointer;
    width: 10rem;
    min-width: 9rem;
  }

  .mainContent {
    display: flex;
    flex-direction: row;
  }
}
