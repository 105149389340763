ul.settings-sidebar {
  list-style: none;
  padding: 0;

  a {
    min-height: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
    word-break: break-all;
    padding-right: 1rem;
    color: grey;
    position: relative;
    border-left: 4px solid transparent;

    span {
      padding-left: 1.5rem;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0;
      background: var(--color-primary-dark);
    }

    &.active {
      border-left: 4px solid var(--color-primary-dark);
      font-weight: bold;
      color: var(--color-primary-dark);

      &:before {
        opacity: 0.2;
      }
    }

    &:hover {
      background: white;
    }
  }
}
