article {
  display: flex;
  flex: 1;
  flex-direction: column;

  .last-edited {
    color: grey;
  }

  .breadcrumbs {
    a,
    span {
      color: gray;
    }

    a:hover {
      color: rgb(54, 54, 54);
    }

    .divider {
      margin-inline: 0.5rem;
    }
  }

  .article-body {
    flex: 1;
  }
}
