.multiselect-wrapper {
  width: 200px;
  height: 100%;

  &.is-active {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  }
}

.multiselect__control {
  border: 1px solid #e0e0e0;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  background-color: #fff;
  min-height: 45px;
  position: relative;
  align-content: center;
  border-radius: 0.3rem;
}

.multiselect__arrow-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  transform: translateY(-50%);
  color: #8c8c8c;
  font-size: 14px;
}

.multiselect__result-area {
  border: 1px solid #e0e0e0;
  border-top: none;
  background-color: #fff;
  display: none;

  &.is-active {
    display: block;
    background: white;
    z-index: 1;
    position: absolute;
    width: 200px;
    max-height: 350px;
    height: max-content;
    overflow-y: overlay;
    overflow-x: hidden;
  }
}

.multiselect-results {
  list-style-type: none;
  padding: 0;
}

.multiselect-results__item {
  line-height: 45px;
}

.multiselect-results__item:hover {
  background-color: #f8f8f8;
}

.multiselect-results__item + .multiselect-results__item {
  border-top: 1px solid #e0e0e0;
}

.multiselect-results__item.is-active {
  background-color: #f2fafd;
  border: 1px solid #c7e2ec;
  margin: 0 -1px;
}

.multiselect-results__item.is-active:first-child {
  border-top: none;
}

.multiselect-results__item.is-active:last-child {
  margin-bottom: -1px;
}

.multiselect-results__item.is-active + .multiselect-results__item {
  border-top: none;
}

.custom-checkbox {
  display: none;
}

.custom-checkbox-label {
  position: relative;
  padding: 5px 0 5px 45px;
  display: block;
  cursor: pointer;
  line-height: 26px;
}

.multiselect-results__item.no-options {
  padding: 10px;
  text-align: center;
  color: #666;
  list-style-type: none;
}

.no-options-text {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding-top: 1rem;
}

.custom-checkbox-label::before {
  font-family: FontAwesome;
  width: 16px;
  height: 16px;
  content: '';
  position: absolute;
  left: 14px;
  top: 14px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
}

.custom-checkbox:checked + .custom-checkbox-label::before {
  content: '\f00c';
  background-color: #00a9df;
  color: #fff;
  border-color: #018bb9;
}
