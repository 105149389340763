.admin-banner {
  color: white;
  height: 0;
  text-align: center;
  width: 100%;
  line-height: 2rem;
  transition: all 0.2s ease;
  z-index: 1050;
  background-color: rgb(231, 92, 0);
  overflow-y: hidden;
  border-color: rgb(122, 49, 0);
  border-bottom-width: 0;
  border-bottom-style: solid;

  &.admin-mode {
    height: 2rem;
    overflow-y: visible;
    border-bottom-width: 1px;
  }
  
  &.draft-mode {
    background-color: rgb(12, 150, 0);
    border-color: rgb(7, 88, 0);
  }
  
  #bannerHelpButton:focus {
    background: rgba(black, 0.3) !important;
    box-shadow: none;
  }
}

header {
  display: flex;
  flex-direction: column;
  background: var(--color-primary);
  border-bottom: 2px solid white;
  width: 100vw;
  height: fit-content;
  transition: height 0.2s ease;
  overflow-x: clip;
  overflow-y: visible;
  position: relative;

  .content {
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
  }

  &.fullscreen {
    height: 3rem;
    min-height: 3rem;
    padding: 0;
    margin: 0;

    .content {
      padding-top: 0.2rem;
    }
  }

  .header-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    height: 4rem;
    min-height: 4rem;

    > .right-container {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;
      margin-left: 2%;

      .search-input {
        display: flex;
        justify-content: flex-end;
        margin-right: 3px;

        .search-input-parent-stretcher.focused-child {
          width: 100%;
        }

        .search-input-parent-stretcher {
          transition: width 0.15s;
          width: 200px;
          color: var(--grayscale-1);

          .search-icon {
            position: absolute;
            top: 0.5rem;
            left: 0.85rem;
          }
        }

        input:focus {
          outline: none;
        }

        input {
          border-color: var(--grayscale-1);
          color: var(--grayscale-1);
          background-color: rgba(white, 0.15);
          border: none;
          transition: 0.2s ease background-color;
        }

        input::placeholder {
          color: var(--grayscale-1);
        }

        input:hover {
          background-color: rgba(white, 0.25);
        }
      }

      .fast-search-result {
        border-bottom: 1px solid #cfcfcf;

        &:nth-child(even) {
          background-color: rgba(#f2f2f2d9, 0.85);
        }

        > svg {
          color: #888;
          padding: 9px 0 0 0;
        }
      }

      .dropdown-item.active {
        background: var(--color-primary-light);
      }
    }

    > .left-container {
      transition: all 0.2s ease;
      height: 2.5rem;
      display: flex;
      min-width: 0;

      h1 {
        font-size: 1.75rem;
        padding-left: 1rem;
        display: inline;
        line-height: 2.5rem;
        color: var(--grayscale-0);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        height: 100%;
      }

      a {
        text-decoration: none;
      }

      img {
        height: 100%;
        flex-shrink: 0;
      }
    }

    button,
    input {
      border-radius: 0.5rem;
      margin: 0;
      margin-left: 0.4em;
    }
  }

  .header-bottom-row {
    //have to exclude this from smaller sizes or it ends up overiding and breaking the phone layout
    @media only screen and (min-width: 1200px) {
      ol {
        display: flex;
        flex-wrap: wrap;
        overflow-x: hidden;
        overflow-y: hidden;
        height: min-content;
        white-space: nowrap;
        li {
          display: inline-block;
        }
      }
    }
    /* chrome rendering bug */
    ol {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        a {
          display: block;
          text-decoration: none;
          color: var(--color-primary-light);
          padding: 10px;
          transition: transform 0.1s ease, font-size 0.2s ease;
          font-size: 1rem;
          height: 2.7rem;

          &:hover {
            transform: scale(1.05);
          }

          &.active {
            font-weight: bold;
            position: relative;

            .triangle-indicator {
              fill: white;
              position: absolute;
              display: unset;
              bottom: 0;
              left: calc(50% - 7px);
            }
          }

          .triangle-indicator {
            display: none;
          }

          &.restricted {
            text-decoration: line-through;
          }

          svg.nav-link-icon {
            margin-left: 0.25rem;
          }
        }
      }
    }
  }

  .header-bottom-row.fullscreen {
    a {
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  //TODO fix placeholder stuff
  .hbr-placeholder:after {
    visibility: hidden;
    content: ' ';
    margin: 10px;
  }

  .search-dropdown {
    flex-grow: 1;

    a:link,
    a:visited,
    a:hover,
    :active {
      text-decoration: none;
      color: grey;
    }

    .dropdown-menu {
      width: 100%;
      padding-bottom: 0;
    }
  }

  .header-top-button {
    display: flex;
    max-width: 600px;
    transition: max-width 0.2s ease;
    overflow-x: clip;
    padding: 0.2rem;
    padding-right: 0.4rem;
    position: relative;
  }
}

// Responsive styles
@media only screen and (max-width: 1400px) {
  .header-bottom-row {
    ol {
      li {
        a {
          font-size: 0.9rem !important;
          padding: 10px 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  header {
    height: 5.2rem;
    min-height: 5.2rem;

    .header-top-button {
      max-width: 0;
    }
  }

  .header-top-category-navigation {
    display: none;
  }

  .header-top-row {
    align-items: center;
  }
}

@media only screen and (max-width: 576px) {
  header {
    .header-top-row {
      > .right-container {
        display: none !important;
      }
    }

    &.fullscreen,
    &.fullscreen.admin-mode {
      height: 0;
    }
  }
}
