.email-page {
  .email-list {
    > div {
      padding: 5px 0 5px 5px;
      border-bottom: 1px solid #d3d3d3;
    }
    > div:last-child {
      border-bottom: none;
    }
    button {
      height: 2.6rem;
      width: 2.6rem;
    }
  }
}
