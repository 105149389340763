.name {
  font-weight: bold;
  padding: 5px;
}

.sectionName {
  font-weight: bolder;
  font-size: large;
}

.mainContent {
  display: flex;
  flex-direction: row;
  gap: 20rem;
}

.category-tree-box {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.list-container {
  margin-bottom: 20px;
}

.item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.no-bullets {
  list-style-type: none;
  padding-left: 5rem;
}
