.table th.up {
  background-image: url('Images/sort_asc.png');
}
.table th.down {
  background-image: url('Images/sort_desc.png');
}
.table th.default {
  background-image: url('Images/sort_default.png');
}

th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}
