.layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;

  .site-body {
    flex-grow: 1;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    overflow: auto;
    transition: margin-right 0.2s ease;
  }

  .site-body.fullscreen {
    margin-right: unset;
  }
}

@media screen and (max-width: 1200px) {
  .site-body {
    margin-right: 3.25rem;
  }
}

@media screen and (max-width: 576px) {
  .site-body {
    margin-right: unset;
  }
}
