.centered-page-box {
  margin-left: auto;
  margin-right: auto;
  width: 720px;
  overflow-x: clip;

  .row {
    --bs-gutter-x: 0rem;
  }

  .login-form {
    padding: 1.5rem;
    padding-top: 3rem;
    .login-header {
      margin-bottom: 2rem;
      h2 {
        color: var(--color-primary);
        font-size: 3rem;
      }
      p {
        color: var(--grayscale-2);
      }
    }
    > * {
      margin: 1em;
    }
    .login-mode-buttons {
      display: flex;
      justify-content: center;
      button {
        width: 9rem;
        border-radius: 0;
      }
    }
    .login-fields {
      label {
        display: block;
      }
      input {
        padding-left: 2em;
      }
    }
    .register-fields {
      .row {
        margin: 0 1.2rem;
      }
      .fields {
        margin-bottom: 1.5rem;
      }
      input {
        padding-left: 2em;
      }
    }
    .register {
      margin: 0 2.4rem;
    }
  }
}

@media screen and (max-width: 750px) {
  .centered-page-box {
    width: 100%;
  }
  .login-form {
    .register-fields {
      .col {
        margin-bottom: 0.5rem;
      }
    }
  }
}
