.editable-content {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'head'
    'body';
  .editable-content-head {
    grid-area: head;
    .btn-primary {
      background-color: var(--color-primary-dark) !important;
      border-radius: 10px;
    }
    .article-location {
      display: inline-block;
    }
    .edit-view-cancel-buttons {
      float: right;
      padding-right: 0.2rem;
      margin-top: -0.5rem;
    }
  }
  .editable-content-body {
    grid-area: body;
    display: grid;
  }
  .editor-wrapper {
    grid-area: 1 / 1 / -1 / -1;
  }

  /* Css for according in change log */
  .changelog-check {
    position: absolute;
    left: -100vw;
  }

  .accordion-article-box {
    margin-bottom: 1em;
    border-radius: 10px;
    overflow: hidden;

    .content-article-box {
      overflow-y: hidden;
      height: 0;
      transition: height 0.3s ease;
      border-radius: 0 0 10px 10px;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          border-bottom: 1px solid #e8e8e8;
          padding: 11px;
        }
        li:last-child {
          border-bottom: none;
        }
      }
    }

    > .changelog-check:checked ~ .content-article-box {
      height: auto;
    }

    label {
      display: block;
      color: #333;
      cursor: pointer;
      font-weight: normal;
      padding: 15px;
      background: #e8e8e8;
    }

    > .changelog-check:checked ~ .content-article-box {
      padding: 0;
      border: 1px solid #e8e8e8;
      border-top: 0;
    }

    label:hover,
    label:focus {
      background: #d8d8d8;
    }

    .handle-article-box {
      margin: 0;
      font-size: 1.125em;
      line-height: 1.2em;

      label:before {
        display: inline-block;
        margin-right: 10px;
        font-size: 1em;
        font-weight: bold;
        vertical-align: middle;
        content: '+';
        width: 10px;
        text-align: center;
      }
    }

    > .changelog-check:checked ~ .handle-article-box label:before {
      content: '-';
    }
  }
  .article-link-changelog {
    text-decoration: underline !important;
  }
  .article-link-changelog:hover {
    text-decoration: none !important;
  }
  .article-link-changelog {
    text-decoration: underline !important;
  }
  .article-link-changelog:hover {
    text-decoration: none !important;
  }
}
