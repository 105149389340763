.react-calendar {
    width: 320px !important;
    height: 320px !important;
    
    button {
        font-size: 13px;
    }
}

.react-calendar__year-view .react-calendar__tile, 
.react-calendar__decade-view .react-calendar__tile, 
.react-calendar__century-view .react-calendar__tile {
    padding: 0.5rem !important;
}