.modal-tooltip {
  white-space: pre-line;
}

.colors-input-row {
  display: flex;
  flex-direction: row; 
  align-items: center;

  p {
    margin: auto 15px;
  }
}